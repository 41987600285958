<template>
	<div class="container">
		<div class="row clearfix displayFlex" id="clearfix">
			<Nav_class  @child-source="parentevent"></Nav_class>
			<div class="column" style="margin: 0 15px;flex: 1 1 auto;">
				<Intelligence ref="Intelligence" style="margin-top"></Intelligence>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getQuestionBank,getZhangJie
	} from '../../api/Source.js'
	import {
		queryMyClassList
	} from '../../api/base.js'
	import Nav_class from '../../components/Nav_class'
	import Intelligence from '../../components/Intelligence/index.vue'
	export default {
		name: 'Class',
		components:{Nav_class,Intelligence},
		data() {
			return {
				difficulty: ['', '简单', '容易', '一般', '困难', '很难'],
				list: 1,
				items: null,
				selected: 0,
				options: null,
				value: '',
				data: null,
				tableData: null,
				StartGroupshow:false,
				chapters:localStorage.getItem("teachchapters")!="undefined"?JSON.parse(localStorage.getItem("teachchapters")):[],
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				isExpand: false,
			}
		},
		watch: {
			chapters(val, oldVal) {
				getQuestionBank({"chapters": val,}).then(response => {
					this.tableData = response.data.tableData
				})
			}
		},
		mounted() {
			this.getbanji();
			if(localStorage.getItem("teachchapters")!="undefined"){
				this.get_question_bank()
			}
		},
		methods: {
			fatherMethodsource(){
				this.$nextTick(()=>{
					if(this.$refs.Intelligence){
						this.$refs.Intelligence.queryrelationlist()
					}
				})
			},
			StartGroup(){
				this.StartGroupshow=!this.StartGroupshow
			},
			parentevent(){
				this.get_question_bank();
			},
			is_selected() {
				getZhangJie({"type": "chapter",'textbookId': data}).then(response => {
					this.data = response.data.result
				})
			},
			get_question_bank() {
				getQuestionBank({"chapters": JSON.parse(localStorage.getItem("teachchapters"))}).then(response => (
					this.tableData = response.data.tableData
				))
			},
			getbanji() {
				queryMyClassList({"userToken": JSON.parse(localStorage.getItem("teachuserToken"))}).then(response=>{
					response.data.result.forEach(item=>{
						item.value=item.id
						item.lable=item.name
					})
					this.options = response.data.result
					this.selected = this.options[0].value
				})
			},
		},
	}
</script>

<style>
	.scoure_title{
		text-align: left;
	    background: #fff;
		padding: 20px;
		margin-top:20px;
	}
	.scoure_title h3{
		font-size: 16px;
	}
	.scoure_title1{
		width: 88px;
		height: 40px;
		background: #D7DBE2;
		border-radius: 2px 0px 0px 2px;
		font-size: 14px;
		font-family: SourceHanSansCN-Regular, SourceHanSansCN;
		font-weight: 400;
		color: #13264D;
		text-align: center;
		line-height: 40px;
		margin-bottom: 17px;
	}
</style>
