<template>
	<div>
		<div class="Intelligence" id="Intelligence">
			<!-- 中间 -->
			<div class="IntelligenceLeft">
				<div class="marginBottom20">
					<el-radio-group v-model="radio1">
						<el-radio-button label="1">题目</el-radio-button>
						<el-radio-button label="2">文件</el-radio-button>
					</el-radio-group>
				</div>
				<!-- 题目 -->
				<div v-if="radio1=='1'">
					<!-- 知识点 -->
					<div>
						<span class="conLeft">{{$store.state.teachlanguagedata.knowledgepoints}}</span>
						<el-select clearable filterable v-model="data.ids" multiple collapse-tags style="margin-left: 20px;"
							:placeholder="$store.state.teachlanguagedata.select">
							<el-option v-for="item in RelationList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
					<!-- 难易度 -->
					<div style="margin-top:20px;">
						<span class="conLeft">{{$store.state.teachlanguagedata.difficulty}}</span>
						<el-select clearable filterable style="margin-left: 20px;" v-model="data.difficult"
							:placeholder="$store.state.teachlanguagedata.select">
							<el-option v-for="item in Basic.BasicDifficulty" :key="item.code" :label="item.name"
								:value="item.code">
							</el-option>
						</el-select>
					</div>
					<!-- 题型及分值明细 -->
					<div style="margin-top:20px;">
						<div @click="dialogVisible=true">
							<span>{{$store.state.teachlanguagedata.questionsetscoredetails}}</span>
							<img class="" style="margin-left:20px;" src="../../assets/img/iconbianji.png">
						</div>
						<div v-for="item in data.featureList" style="margin-top:10px;">
							<span
								class="IntelligenceLeftASpan">{{item.featureCode | featureName(Basic.BasicQuestiontype)}}</span>
							<span
								class="IntelligenceLeftASpan">({{item.count}}{{$store.state.teachlanguagedata.topic}})</span>
						</div>
					</div>
					<!-- 确定重置 -->
					<div style="margin-top:20px;text-align: center;">
						<button class="IntelligenceButton">{{$store.state.teachlanguagedata.reset}}</button>
						<button class="IntelligenceButton IntelligenceButtonbu"
							@click="queryailistbycatalogtactics()">{{$store.state.teachlanguagedata.confirm}}</button>
					</div>
				</div>
				<!-- 文件 -->
				<div v-if="radio1=='2'">
					<div class="displayFlex alignItemsCenter"><span class="whiteSpaceNowrap">名称：</span>
						<el-input v-model="resourceSave.name"></el-input>
					</div>
					<div class="displayFlex alignItemsCenter">
						<span>上传文件:</span>
						<el-upload style="margin: 20px 10px;display: inline-block;" class="upload-demo" ref="upload"
							action="#" :on-preview="batchImportbi" :auto-upload="false">
							<el-button slot="trigger" size="small" type="primary">上传文件</el-button>
						</el-upload>
					</div>
					<!-- <div class="displayFlex alignItemsCenter">
						<span>是否铺码:</span>
						<el-select style="margin-left: 20px;" v-model="resourceSave.noCode"
							:placeholder="$store.state.teachlanguagedata.select">
							<el-option v-for="item in [{name:'是',id:'0'},{name:'否',id:'1'}]" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div> -->
					<div style="margin-top:20px;text-align: center;">
						<button class="IntelligenceButton">取消</button>
						<button class="IntelligenceButton IntelligenceButtonbu" @click="getResourceSave()">确定</button>
					</div>
				</div>
			</div>
			<!-- 右侧 -->
			<div class="IntelligenceRight positionRelative">
				<TopicAssembly v-if="radio1=='1'&&tableData.length>0" class="IntelligenceRightimg"
					:tableData="tableData"></TopicAssembly>
				<div v-if="radio1=='2'&&paginationData.list&&paginationData.list.length>0" class="IntelligenceRightimg">
					<div>
						<h3>资源列表</h3>
						<!-- 	<el-radio-group v-model="radio2">
							<el-radio-button label="3">全部资源</el-radio-button>
							<el-radio-button label="4">章节资源</el-radio-button>
						</el-radio-group> -->
						<BaseTable @changeIndexOrPage="resourceQueryList" :tableData="paginationData.list"
							:paginationData="paginationData" :tableColumn="tableColumn"></BaseTable>
					</div>
				</div>
				<NoData v-if="radio1=='1'&&tableData.length<1"></NoData>
				<NoData v-if="radio1=='2'&&paginationData.list&&paginationData.list.length<1"></NoData>
			</div>
			<el-dialog :title="$store.state.teachlanguagedata.ProblemSetStrategy" :visible.sync="dialogVisible"
				width="30%">
				<el-table :data="data.featureList" style="width: 100%" max-height="250" :header-cell-style="rowClass">
					<el-table-column :label="$store.state.teachlanguagedata.questiontype">
						<template slot-scope="scope">
							<el-select clearable filterable v-model="scope.row.featureCode"
								:placeholder="$store.state.teachlanguagedata.select" value-key="valueName">
								<el-option v-for="(item,index) in Basic.BasicQuestiontype" :key="item.valueCode"
									:label="item.valueName" :value="item.valueCode">
								</el-option>
							</el-select>
						</template>
					</el-table-column>
					<el-table-column :label="$store.state.teachlanguagedata.quantity">
						<template slot-scope="scope">
							<el-input v-model="scope.row.count"
								:placeholder="$store.state.teachlanguagedata.enterthecontent"></el-input>
						</template>
					</el-table-column>
					<el-table-column :label="$store.state.teachlanguagedata.operate">
						<template slot-scope="scope">
							<el-button @click.native.prevent="deleteRow(scope.$index, data.featureList)" type="text"
								size="small">
								{{$store.state.teachlanguagedata.delete}}
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<span @click="addNewTypeArr()">{{$store.state.teachlanguagedata.Addquestiontype}}</span>
				<span slot="footer" class="dialog-footer">
					<el-button @click="dialogVisible=false">{{$store.state.teachlanguagedata.cancel}}</el-button>
					<el-button type="primary" @click="Defaultpolicy()">{{$store.state.teachlanguagedata.confirm}}
					</el-button>
				</span>
			</el-dialog>
		</div>
		<div class="IntelligenceSheng" v-if="radio1=='1'&&tableData.length>0">
			<span class="IntelligenceShengspan" @click="generate()">{{$store.state.teachlanguagedata.preview}}</span>
			<span class="IntelligenceShengspan"
				@click="createbookset()">{{$store.state.teachlanguagedata.posthomework}}</span>
		</div>
		<!-- 发布作业 -->
		<PublishJob ref="PublishJob" :CreateAiQuestionSetShow="CreateAiQuestionSetShow"></PublishJob>
		<!-- 组题策略 -->
		<!-- <QuestionFormationStrategy ref="QuestionFormationStrategy"></QuestionFormationStrategy> -->
	</div>
</template>

<script>
	import {
		showLoading,
		hideLoading
	} from '../../utils/Loading.js'
	import {
		getZhangJie,
		taskSave
	} from '../../api/Source.js'
	import {
		queryListByDictValue,
		localConvertPdf,
		resourceSave,
		resourceQueryList,
		queryMyClassList
	} from '../../api/base.js'
	import {
		queryAiListByCatalogTactics,
		queryRelationList,
		queryAllTypeList,
		aiQuestionPdf,
		createAiQuestionSet,
	} from '../../api/Intelligence.js'
	import Basic from '../../assets/json/base.json'
	import TopicAssembly from '../TopicAssembly.vue'
	import NoData from '../BasicComponents/NoData.vue'
	import BaseTable from '../BasicComponents/BaseTable.vue'
	import PublishJob from '../PublishJob/index.vue'
	import QuestionFormationStrategy from '../QuestionFormationStrategy/index.vue'
	export default {
		name: 'Intelligence',
		components: {
			TopicAssembly,
			NoData,
			BaseTable,
			PublishJob,
			QuestionFormationStrategy
		},
		data() {
			return {
				CreateAiQuestionSetShow: 1, // 是否创建题集
				paginationData: {
					currPage: 1,
					pageSize: 10,
				},
				tableColumn: [{
					prop: "index",
					label: "序号",
					align: 'center'
				}, {
					prop: "name",
					label: "名称",
					align: 'center'
				}, {
					prop: "createByName",
					label: "创建老师",
					align: 'center'
				}],
				textbooklist: {},
				radio1: '1',
				radio2: '3',
				selected: '',
				resourceSave: {
					noCode: '0'
				},
				options: null,
				planKinds: [],
				stageKinds: [],
				datazhangjie: [],
				form: {},
				worksavename: '',
				formLabelWidth: '200px',
				worksaveshow: false,
				tableData: [],
				Basic,
				RelationList: [],
				dialogVisible: false,
				defaultProps: {
					children: 'children',
					label: 'label'
				},
				localConvertPdfData: {},
				data: {
					difficult: '3',
					ids: [],
					featureList: [{
							"count": 5,
							"featureCode": "100001"
						},
						{
							"count": 3,
							"featureCode": "400001"
						}
					]
				}
			}
		},
		filters: {
			featureName(val, BasicQuestiontype) {
				let data;
				BasicQuestiontype.forEach((item) => {
					if (item.valueCode == val) {
						data = item.valueName
					}
				})
				return data
			}
		},
		watch: {
			radio1(val) {
				if (val == 2) {
					this.resourceQueryList()
				}
			}
		},
		mounted() {
			const testJson = require('../../assets/json/base.json');
			this.BasicQuestiontype=testJson.BasicQuestiontype
			this.queryalltypelist()
			this.querylistbydictvalue('plan_kind', 'planKinds')
			this.querylistbydictvalue('stage_kind', 'stageKinds')
			this.getbanji()
		},
		methods: {
			resourceQueryList() {
				let data = {
					"currPage": this.paginationData.currPage,
					"pageSize": this.paginationData.pageSize,
					corpId: JSON.parse(localStorage.getItem("teachlogin")).corpId
				}
				resourceQueryList(data).then(res => {
					this.paginationData = res.data.result
				})
			},
			getResourceSave() {
				this.textbooklist = JSON.parse(localStorage.getItem('textbooklist'))
				let teachchapters = JSON.parse(localStorage.getItem('teachchapters'))
				if (teachchapters) {
					if (teachchapters.length != 1) {
						this.$message.error("请选择一个章节")
						return
					}
				} else {
					this.$message.error("请选择一个章节")
					return
				}
				let data = {
					name: this.resourceSave.name,
					position: this.textbooklist.position,
					subjectId: this.textbooklist.subjectId,
					editionId: this.textbooklist.editionId,
					textbookId: this.textbooklist.textbookId,
					url: this.localConvertPdfData.visitPath,
					images: this.localConvertPdfData.images,
					width: this.localConvertPdfData.width,
					height: this.localConvertPdfData.height,
					noCode: this.resourceSave.noCode,
					catalogSetRelationList: [{
						relationId: teachchapters[0],
						relationKind: "chapter"
					}],
				}
				showLoading()
				resourceSave(data).then(res => {
					this.$message("创建成功")
					this.resourceQueryList()
					hideLoading()
				}).catch(res => {
					hideLoading()
				})
			},
			batchImportbi(file, fileList) {
				let fd = new FormData()
				fd.append("file", file.raw)
				showLoading()
				localConvertPdf(fd).then(res => {
					this.localConvertPdfData = res.data.data
					this.$message("上传成功")
					hideLoading()
				}).catch(res => {
					hideLoading()
				})
			},
			getbanji() {
				let data = {
					'userToken': JSON.parse(localStorage.getItem("teachuserToken")),
				}
				queryMyClassList(data).then(response => {
					response.data.result.forEach(item => {
						item.value = item.id
						item.lable = item.name
					})
					this.options = response.data.result
					this.selected = []
					JSON.parse(localStorage.getItem('teachclass_id'))
					this.options.forEach(item => {
						if (item.value == JSON.parse(localStorage.getItem('teachclass_id'))) {
							this.selected.push(item)
						}
					})
				})
			},
			querylistbydictvalue(n, value) {
				queryListByDictValue('dictValue=' + n).then(res => {
					this[value] = res.data.result;
				})
			},
			handleCheckChange(data, checked) {
				if (checked) {
					this.chapter = data
					this.chapterId = data.id
					this.$refs.tree.setCheckedKeys([data.id])
				} else {}
			},
			generate() {
				let data = {
					questionOutList: this.tableData
				}
				aiQuestionPdf(data).then((res) => {
					let content = res.data;
					let elink = document.createElement("a");
					elink.download = "智能组题.pdf";
					elink.style.display = "none";
					let blob = new Blob([content], {
						type: "application/pdf"
					})
					elink.href = URL.createObjectURL(blob);
					document.body.appendChild(elink);
					elink.click();
					document.body.removeChild(elink);
				})
			},
			createbookset() {
				this.$refs.PublishJob.init()
			},
			worksave() {
				let data1 = {
					position: JSON.parse(localStorage.getItem("teachsubjects")).filter((item) => {
						return item.id == JSON.parse(localStorage.getItem("teachsubjectId"))
					})[0].position,
					questionOutList: this.tableData,
					subjectId: JSON.parse(localStorage.getItem("teachsubjectId")),
					textbookId: JSON.parse(localStorage.getItem("textbookId")),
					catalogSetRelationList: [{
						relationId: this.chapter.id,
						relationKind: "chapter",
					}],
				}
				createAiQuestionSet(data1).then((res) => {
					let userRelationList = []
					this.selected.forEach(item => {
						let userRelation = {}
						userRelation.relationId = item.value
						userRelation.relationKind = 'class'
						userRelation.relationName = item.lable
						userRelationList.push(userRelation)
					})
					let data2 = {
						beginTime: this.form.beginTime,
						endTime: this.form.endTime,
						name: this.worksavename,
						subjectId: JSON.parse(localStorage.getItem("teachsubjectId")),
						planKind: this.form.planKind,
						resourceRelationList: [{
							relationId: res.data.result.id,
							relationKind: "questionSet",
							relationName: res.data.result.name
						}, {
							relationId: this.chapter.id,
							relationKind: "chapter"
						}],
						stageKind: this.form.stageKind,
						userRelationList: userRelationList
					}
					taskSave(data2).then((res) => {
						this.worksaveshow = false
					})
				})
			},
			unique(arr) {
				for (var i = 0; i < arr.length; i++) {
					arr.index = i + 1
				}
				return arr;
			},
			queryalltypelist() {
				queryListByDictValue('dictValue=' + 'question_feature_code').then(res => {
					this.Basic.BasicQuestiontype = res.data.result
				})
			},
			Mathqunati() {
				this.$nextTick(function() { //这里要注意，使用$nextTick等组件数据渲染完之后再调用MathJax渲染方法，要不然会获取不到数据
					if (this.commonsVariable.isMathjaxConfig) { //判断是否初始配置，若无则配置。
						this.commonsVariable.initMathjaxConfig();
					}
					this.commonsVariable.MathQueue("Intelligence");
				})
			},
			rowClass() {
				return 'background:rgba(242, 242, 242, 1)'
			},
			addNewTypeArr() {
				this.data.featureList.push({
					count: 5,
					featureCode: '',
				})
			},
			deleteRow(index, rows) {
				rows.splice(index, 1);
			},
			//知识点
			queryrelationlist() {
				if (JSON.parse(localStorage.getItem("teachchapters")) == '') {
					this.RelationList = []
					return
				}
				let data = {
					"mainIds": JSON.parse(localStorage.getItem("teachchapters")),
					"relationKind": "keyPoint"
				}
				queryRelationList(data).then(res => {
					this.RelationList = res.data.result
					this.data.ids = []
				})
			},
			Defaultpolicy() {

				this.dialogVisible = false
				this.$forceUpdate();
			},
			//组题
			queryailistbycatalogtactics() {
				let featureList = this.data.featureList
				let data = {
					"difficult": this.data.difficult,
					"featureList": featureList,
					"ids": this.data.ids,
					"relationKind": "keyPoint",
					"subjectId": JSON.parse(localStorage.getItem("teachsubjectId"))
				}
				if (!data.ids.length > 0) {
					this.$message({
						message: '知识点为空',
						type: 'warning'
					});
					return
				} else if (!data.difficult) {
					this.$message({
						message: '难易度为空',
						type: 'warning'
					});
					return
				}
				for (let i = 0; i < data.featureList.length; i++) {
					if (!data.featureList[i].featureCode) {
						this.$message({
							message: '题型为空',
							type: 'warning'
						});
						return
					} else if (!data.featureList[i].count) {
						this.$message({
							message: '题型数量为空',
							type: 'warning'
						});
						return
					}
				}
				queryAiListByCatalogTactics(data).then(res => {
					if (res.data.code == '0000') {
						res.data.result.forEach((item, index) => {
							item.content = this.textcircled(item.content)
							item.answerList.forEach((answerListitem, answerListindex) => {
								if (answerListitem.value.indexOf('@@@@') != -1) {
									answerListitem.value = answerListitem.value.replace(/@@@@/g,
										'')
									answerListitem.value = answerListitem.value.replace(/\$/g, '')
								}
							})
						})
						this.tableData = res.data.result
						this.Mathqunati()
						if(res.data.result&&res.data.result.length>0){
							// this.$message({
							// 	message: '组题成功',
							// 	type: 'success'
							// });
							return
						}else{
							this.$message({
								message: '暂无数据',
								type: 'success'
							});
						}
					}
				})
			},
			textcircled(val) {
				if (val) {
					let a = ['①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫']
					let data = val.replace(/\\textcircled {(1)}/g, a[0])
					data = data.replace(/\\textcircled {(2)}/g, a[1])
					data = data.replace(/\\textcircled {(3)}/g, a[2])
					data = data.replace(/\\textcircled {(4)}/g, a[3])
					data = data.replace(/\\textcircled {(5)}/g, a[4])
					data = data.replace(/\\textcircled {(6)}/g, a[5])
					data = data.replace(/\\textcircled {(7)}/g, a[6])
					data = data.replace(/\\textcircled {(8)}/g, a[7])
					data = data.replace(/\\textcircled {(9)}/g, a[8])
					data = data.replace(/\\textcircled {(10)}/g, a[9])
					data = data.replace(/\\textcelsius/g, "度")
					data = data.replace(/&amp;nbsp;/g, ' ')
					return data
				}
			},
		}
	}
</script>

<style scoped>
	.Intelligence {
		display: flex;
		background: #fff;
		border-top: 1px solid #D8DEEA;
		text-align: left;
		padding-top: 20px;
		flex-wrap: wrap;
	}

	.IntelligenceLeft {
		padding-right: 24px;
		padding: 20px;
		border-right: 1px solid #D8DEEA;
	}

	.IntelligenceRight {
		padding: 20px;
		flex: 1 1 auto;
		height: 78vh;
		overflow: auto;
		padding-top: 0;
	}

	.IntelligenceRightimg {
		height: 100%;
	}

	.IntelligenceLeftASpan {
		height: 24px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #6e7c94;
		line-height: 24px;
	}

	.conLeft {
		height: 22px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #6E7C94;
		line-height: 22px;
	}

	.IntelligenceButton {
		width: 68px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 2px;
		border: 1px solid #D8DEEA;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #19409E;
		line-height: 20px;
	}

	.IntelligenceButtonbu {
		color: #FFFFFF;
		background: #19409E;
		margin-left: 40px;
	}

	.IntelligenceSheng {
		margin-top: 20px;
	}

	.IntelligenceShengspan {
		height: 40px;
		background: #19409e;
		border-radius: 2px;
		font-size: 14px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #fff;
		padding: 12px 20px;
	}
</style>
