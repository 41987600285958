<template>
	<el-dialog :title="$store.state.teachlanguagedata.ProblemSetStrategy" :visible.sync="dialogVisible" width="30%" style="text-align: left;">
		<el-table :data="groupQuestions.featureList" style="width: 100%" max-height="250" :header-cell-style="rowClass">
			<el-table-column :label="$store.state.teachlanguagedata.questiontype">
				<template slot-scope="scope">
					<el-select clearable filterable v-model="scope.row.featureCode" :placeholder="$store.state.teachlanguagedata.select"
						value-key="valueName">
						<el-option v-for="(item,index) in Basic.BasicQuestiontype" :key="item.valueCode"
							:label="item.valueName" :value="item.valueCode">
						</el-option>
					</el-select>
				</template>
			</el-table-column>
			<el-table-column :label="$store.state.teachlanguagedata.quantity">
				<template slot-scope="scope">
					<el-input v-model="scope.row.count" :placeholder="$store.state.teachlanguagedata.enterthecontent">
					</el-input>
				</template>
			</el-table-column>
			<el-table-column :label="$store.state.teachlanguagedata.operate">
				<template slot-scope="scope">
					<el-button @click.native.prevent="deleteRow(scope.$index, groupQuestions.featureList)" type="text"
						size="small">
						{{$store.state.teachlanguagedata.delete}}
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="margin-top: 20px;" @click="addNewTypeArr()">{{$store.state.teachlanguagedata.Addquestiontype}}</div>
		<div slot="footer" class="dialog-footer">
			<el-button @click="dialogVisible=false">{{$store.state.teachlanguagedata.cancel}}</el-button>
			<el-button type="primary" @click="Defaultpolicy()">{{$store.state.teachlanguagedata.confirm}}
			</el-button>
		</div>
	</el-dialog>
</template>

<script>
	import {
		queryListByDictValue
	} from '../../api/base.js'
	import baseJson from '../../assets/json/base.json'
	export default {
		name: 'Intelligence',
		data() {
			return {
				dialogVisible: true,
				Basic:{
					BasicQuestiontype:null
				},
				groupQuestions: baseJson.groupQuestions
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init(){
				this.querylistbydictvalue('question_feature_code', 'Basic.BasicQuestiontype',1)
			},
			// 获取基础题型
			querylistbydictvalue(n, value,stutes) {
				queryListByDictValue('dictValue=' + n).then(res => {
					if(stutes==1){
						this.Basic.BasicQuestiontype= res.data.result;
					}else{
						this[value] = res.data.result;
					}
				})
			},
			rowClass() {
				return 'background:rgba(242, 242, 242, 1)'
			},
			addNewTypeArr() {
				this.groupQuestions.featureList.push({
					"count": 5,
					"featureCode": "",
				})
			},
			deleteRow(index, rows) {
				rows.splice(index, 1);
			},
			// 获取题型
			Defaultpolicy() {
				this.dialogVisible = false
				// this.$forceUpdate();
			},
		},
	}
</script>

<style>
</style>
